.bff-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
}

.BFF-Page .MuiPickersBasePicker-pickerView {
  max-width: unset;
}
